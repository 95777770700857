<template>
<!--   <div>
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="3"
        sm="6"
      >
        <analytic-one-card
          main-icon-name="mdi-certificate"
          main-icon-background-color-class="primary lighten-5"
          main-icon-text-color="primary--text"
          sub-heading-text="Quartal 1"
          heading-text=""
          bottom-button-text="Erfahrungsnoten"
          bottom-button-icon="mdi-arrow-down-drop-circle-outline"
          bottom-button-hover-class="hover:bg-primary"
          :bottom-button-link="`/${$i18n.locale}/app/apps/experience-marks-pma`"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="3"
        sm="6"
      >
        <analytic-one-card
          main-icon-name="mdi-certificate"
          main-icon-background-color-class="success lighten-5"
          main-icon-text-color="success--text"
          sub-heading-text="Quartal 1"
          heading-text=""
          bottom-button-text="Zwischenbericht"
          bottom-button-icon="mdi-arrow-down-drop-circle-outline"
          bottom-button-hover-class="hover:bg-success"
          :bottom-button-link="`/${$i18n.locale}/app/apps/quarter-marks-pma`"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="3"
        sm="6"
      >
        <analytic-one-card
          main-icon-name="mdi-certificate"
          main-icon-background-color-class="info lighten-5"
          main-icon-text-color="info--text"
          sub-heading-text="Quartal 1"
          heading-text=""
          bottom-button-text="Semesternoten"
          bottom-button-icon="mdi-arrow-down-drop-circle-outline"
          bottom-button-hover-class="hover:bg-info"
          :bottom-button-link="`/${$i18n.locale}/app/apps/semester-marks-pma`"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="3"
        sm="6"
      >
        <analytic-one-card
          main-icon-name="mdi-certificate"
          main-icon-background-color-class="warning lighten-5"
          main-icon-text-color="warning--text"
          sub-heading-text="Quartal 1"
          heading-text=""
          bottom-button-text="Abschlussnoten"
          bottom-button-icon="mdi-arrow-down-drop-circle-outline"
          bottom-button-hover-class="hover:bg-warning"
          :bottom-button-link="`/${$i18n.locale}/app/apps/final-marks-pma`"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12">
        <base-card>
          <v-card-title>Fächer</v-card-title>
          <v-card-text>
            <v-list
              two-line
              subheader
            >
              <v-list-item
                v-for="course in courses"
                :key="course.id"
                :href="course.link"
              >
                <v-list-item-avatar>
                  <v-icon
                    :class="[course.iconClass]"
                    v-text="course.icon"
                  />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-text="course.title" />
                  <v-list-item-subtitle v-text="course.subtitle" />
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </base-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <base-card class>
          <v-card-title>Stundenplan</v-card-title>
          <v-card-text>
            <div>
              <v-sheet height="600">
                <v-calendar
                  ref="calendar"
                  v-model="value"
                  :weekdays="weekday"
                  type="week"
                  :events="events"
                  event-overlap-mode="stack"
                  :event-overlap-threshold="30"
                  :event-color="getEventColor"
                  @change="getEvents"
                  @click:event="showEvent"
                />
                <v-menu
                  v-model="selectedOpen"
                  :close-on-content-click="false"
                  :activator="selectedElement"
                  offset-x
                >
                  <v-card
                    color="grey lighten-4"
                    min-width="350px"
                    flat
                  >
                    <v-toolbar
                      :color="selectedEvent.color"
                      dark
                    >
                      <v-toolbar-title v-html="selectedEvent.name" />
                      <v-spacer />
                    </v-toolbar>
                    <v-card-text>
                      Raum: <span v-html="selectedEvent.room" /><br>
                      Lehrer: <span v-html="selectedEvent.teacher" />
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        text
                        color="secondary"
                        @click="selectedOpen = false"
                      >
                        Cancel
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-sheet>
            </div>
          </v-card-text>
        </base-card>
      </v-col>
    </v-row>
  </div> -->
  <div></div>
</template>
<script>
  import analyticOneCard from '@/components/card/AnalyticCardVersionOne'
  import analyticTwoCard from '@/components/card/AnalyticCardVersionTwo'
  import {
    analyticOne,
    analyticTwo,
    analyticThree,
    analyticFour,
    analyticFive,
  } from '@/data/analytic2'
  export default {
    name: 'Teacher',
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Teacher',
    },
    components: {
      'analytic-one-card': analyticOneCard,
      'analytic-two-card': analyticTwoCard,
    },
    data () {
      return {
        analyticOne,
        analyticTwo,
        analyticThree,
        analyticFour,
        analyticFive,
        courses: [
          {
            icon: 'mdi-folder',
            iconClass: 'grey lighten-1 white--text',
            subtitle: 'KV Dienstleistung & Administration',
            title: 'Administration & Handel',
            link: 'students-list-pma',
          },
          {
            icon: 'mdi-folder',
            iconClass: 'blue lighten-1 white--text',
            subtitle: 'KV Dienstleistung & Administration',
            title: 'Administration',
            link: 'students-list-pma',
          },
          {
            icon: 'mdi-folder',
            iconClass: 'green lighten-1 white--text',
            subtitle: 'KV Dienstleistung & Administration',
            title: 'Wirtschaft und Gesellschaft',
            link: 'students-list-pma',
          },
        ],
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        value: '',
        weekday: [0, 1, 2, 3, 4, 5, 6],
        events: [],
        colors: [
          'blue',
          'indigo',
          'deep-purple',
          'cyan',
          'green',
          'orange',
          'grey darken-1',
        ],
        names: [
          'Informatik',
          'Administration',
          'Mathematik',
          'Geschichte',
          'Technik',
          'Politik',
          'Umwelt',
          'Deutsch',
        ],

      }
    },
    methods: {
      getEvents ({ start, end }) {
        const events = []

        const min = new Date(`${start.date}T00:00:00`)
        const max = new Date(`${end.date}T23:59:59`)
        const days = (max.getTime() - min.getTime()) / 86400000
        const eventCount = this.rnd(days, days + 20)

        for (let i = 0; i < eventCount; i++) {
          const allDay = false
          const firstTimestamp = this.rnd(min.getTime(), max.getTime())
          const first = new Date(firstTimestamp - (firstTimestamp % 900000))
          const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
          const second = new Date(first.getTime() + secondTimestamp)

          events.push({
            name: this.names[this.rnd(0, this.names.length - 1)],
            start: this.formatDate(first, !allDay),
            end: this.formatDate(second, !allDay),
            color: this.colors[this.rnd(0, this.colors.length - 1)],
            room: '101',
            teacher: 'Hans Muster',
          })
        }

        this.events = events
      },
      getEventColor (event) {
        return event.color
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      formatDate (a, withTime) {
        return withTime
          ? `${a.getFullYear()}-${a.getMonth() +
            1}-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
          : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
    },
  }
</script>
